import React, { FC } from 'react';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import {
  SanityImage,
  SanitySquareImageSliderEntryFragment,
} from 'graphql-types';

import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { SanityRichText } from 'components/Common/SanityRichText';

import { Container, TextContainer } from './SquareImageSliderEntry.styles';

export const SquareImageSliderEntry: FC<SanitySquareImageSliderEntryFragment> = ({
  image,
  _rawCaption,
}) => {
  const gatsbyImage = getSimplifiedImageData(image as SanityImage);

  return (
    <Container>
      {gatsbyImage ? (
        <GatsbyImage image={gatsbyImage} alt="In-House Expert" />
      ) : null}
      <TextContainer>
        {_rawCaption && <SanityRichText blocks={_rawCaption} />}
      </TextContainer>
    </Container>
  );
};
