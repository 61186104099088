import styled from 'styled-components';
import {
  background,
  width,
  height,
  BackgroundProps,
  color,
  space,
  ColorProps,
  flexDirection,
  alignItems,
} from 'styled-utils';
import { GatsbyImage } from '../GatsbyImage';

export const Container = styled.div<BackgroundProps>`
  display: flex;
  flex-direction: column;
  ${background};
`;

export const TitleContainer = styled.header<ColorProps>`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${space({ pt: ['30px'], pb: ['8px'] })};
`;

export const Title = styled.h5<ColorProps>`
  font-size: 14px;
  text-align: center;
  ${color};
  margin: 0;
  padding: 0;
`;

export const InfoCardsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  ${alignItems({ alignItems: ['center', 'center', 'flex-start'] })};
  ${flexDirection({ flexDirection: ['column', 'column', 'row'] })};
  ${space({ pt: ['60px'], pb: ['60px'] })};
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${width({ width: ['100%', '272px'] })};
`;

export const Icon = styled(GatsbyImage)`
  ${height({ height: ['54px'] })};
  align-self: center;
  margin-bottom: 0;
`;

export const Info = styled.div`
  text-align: center;
  margin: 0 auto;
  ${space({ pb: ['50px'] })};
`;
export const InfoText = styled.span<ColorProps>`
  ${color};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 14px;
  line-height: 16px;
`;
