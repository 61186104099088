import React, { FunctionComponent } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { SliderContainer, DotsContainer, Dot } from './Slider.styles';
import KeenSlider from 'keen-slider/react';

export interface SliderProps {
  children: React.ReactElement[];
  handleAfterSlide?: (instanceKeenSlider: KeenSlider) => void;
  interval?: number;
  autoRotate?: boolean;
}

export const Slider: FunctionComponent<SliderProps> = ({
  children,
  interval = 5000,
  autoRotate = false,
}): React.ReactElement => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  // Doc: https://keen-slider.io/api/#api
  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: 2,
    spacing: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  return (
    <SliderContainer>
      <div
        className="keen-slider"
        ref={ref}
        style={{
          visibility: slider ? 'visible' : 'hidden',
        }}
      >
        {children.map((slide, idx) => (
          <div key={idx} className="keen-slider__slide">
            {slide}
          </div>
        ))}
      </div>
      {slider && (
        <DotsContainer>
          {[...Array(slider.details().size - 1).keys()].map(
            (slideIndex): React.ReactElement => (
              <Dot
                data-test-id="dot"
                key={slideIndex}
                onClick={(): void => {
                  slider.moveToSlide(slideIndex);
                }}
                isActive={slideIndex === currentSlide}
              />
            )
          )}
        </DotsContainer>
      )}
    </SliderContainer>
  );
};
