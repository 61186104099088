import styled from 'styled-components';
import { grid, space, typography } from 'styled-utils';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 308px);
  justify-content: space-between;
  @media (max-width: 1311px) {
    justify-content: center;
  }
  grid-row-gap: 24px;
  ${grid({
    gridTemplateColumns: ['1fr 1fr', 'repeat(auto-fill, 308px)'],
    gridColumnGap: [15, 16],
  })};
  ${space({ pt: [16, 24], pb: [32, 60] })};
`;

export const Title = styled.h2`
  margin: 15px;
  text-align: center;
  letter-spacing: normal;
  line-height: 36px;
  ${typography({ fontSize: [26, 32] })};
`;

export const Description = styled.div`
  ${space({ mx: [20, 'auto'], my: 35 })};
  max-width: 830px;
  text-align: center;
  color: ${({ theme }) => theme.colors.basic600};
  ${typography({ fontSize: [14, 18], lineHeight: ['20px', '24px'] })};
`;
