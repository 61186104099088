import styled from 'styled-components';
import { space, layout } from 'styled-utils';

export const SliderContainer = styled.div`
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  display: flex;
  .keen-slider {
    display: flex;
    width: 100%;
    overflow: hidden;

    &__slide {
      max-width: 176px;
      overflow: hidden;
    }

    &[data-keen-slider-v] {
      flex-wrap: wrap;
    }

    &[data-keen-slider-moves] * {
      pointer-events: none;
    }
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 30px 0 47px;
`;

export const Dot = styled.button<{ isActive?: boolean }>`
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: 0;
  }
  background: ${({ isActive, theme }): string =>
    isActive ? theme.colors.blue400 : theme.colors.basic400};
  border-radius: 50%;
  ${layout({ size: ['8px'] })};
  ${space({ mx: ['4px'] })};
`;
