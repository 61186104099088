import React from 'react';
import { graphql } from 'gatsby';

import styled from 'styled-components';

export const StyledDivider = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.basic400};
`;

export const Divider = (): React.ReactElement => {
  return <StyledDivider />;
};

export const query = graphql`
  fragment SanityDivider on SanityDivider {
    _type
    __typename
    name
  }
`;
