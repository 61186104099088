import styled from 'styled-components';

export const HeaderAwareAnchor = styled.div`
  display: block;
  position: relative;
  top: -60px;
  @media (min-width: 980px) {
    top: -70px;
  }
  visibility: hidden;
`;
