import React from 'react';
import {
  SanityCollectionGridBlockFragment,
  SanityLadderCollectionGridBlock,
} from 'graphql-types';
import { useIsBrowser, useUserData } from '@bbnb/openfit-frontend-shared';

import { CollectionGrid } from './CollectionGrid';
import { getProducts } from 'components/Product/Tile/ProductTile.helper';
import { LoadingPlaceHolder } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder';
import { PlaceholderContainer } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder.styles';

interface Props {
  fields: SanityCollectionGridBlockFragment | SanityLadderCollectionGridBlock;
}

export const CollectionGridSanity = ({ fields }: Props) => {
  const { role, loading } = useUserData();

  const isBrowser = useIsBrowser();

  if (loading || !isBrowser) {
    return (
      <PlaceholderContainer>
        <LoadingPlaceHolder width={'1312px'} height={'984px'} />
      </PlaceholderContainer>
    );
  }

  const isEntitled = role === 'subscriber';

  const tiles = getProducts(fields.collection?.collectionProducts, isEntitled);

  return (
    <CollectionGrid
      title={fields.title}
      description={fields.description}
      tiles={tiles}
      collectionName={fields.collection?.name || ''}
    />
  );
};
