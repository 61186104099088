import React from 'react';
import { graphql } from 'gatsby';
import { SeoMetadata } from 'components/SeoMetadata/SeoMetadata';
import { LadderCollectionPageBySlugQuery } from 'graphql-types';
import { renderCollectionPageBlock } from './CollectionPage.blocks';
import { useLogPageViewed } from 'helpers/Amplitude';
import { useTagStandardPageView } from 'helpers/Tealium';
import { useGTMTagStandardPageView } from 'helpers/GTM';
import { LadderApp } from 'templates/ladder/LadderApp';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';

export const CollectionPageTemplate: React.FC<{
  data: LadderCollectionPageBySlugQuery;
}> = ({ data }) => {
  const { slug, blocks, seoMetadata } = data.page || {};

  useLogPageViewed();
  useTagStandardPageView('page_view');
  useGTMTagStandardPageView('page_view');

  return (
    <LadderApp>
      {seoMetadata && slug?.current && (
        <SeoMetadata
          metaData={seoMetadata}
          slug={`collections/${slug.current}`}
        />
      )}
      {blocks
        ?.map((block) => block && renderCollectionPageBlock(block))
        .map((block, index) => (
          <ImageLoadingWrapper key={index} eager={index < 2} children={block} />
        ))}
    </LadderApp>
  );
};

export default CollectionPageTemplate;

// todo needs to implement SanityCollectionPageBlock
export const query = graphql`
  query LadderCollectionPageBySlug($slug: String!) {
    page: sanityLadderCollectionPage(slug: { current: { eq: $slug } }) {
      name
      slug {
        current
      }
      seoMetadata {
        ...SeoMetadata
      }
      blocks {
        ... on SanityEmailSignupBlock {
          ...SanityEmailSignupBlock
        }
        ... on SanitySimpleMediaWithTextBlock {
          ...SanitySimpleMediaWithTextBlock
        }
        ... on SanityRichTextWithBackground {
          ...SanityRichTextWithBackground
        }
        ... on SanitySellPageSpacer {
          ...SanitySellPageSpacer
        }
        ... on SanitySubmenu {
          ...SanitySubmenu
        }
        ... on SanityHeroSlider {
          ...SanityHeroSlider
        }
        ... on SanitySellPageInfoCardsBlock {
          ...SanitySellPageInfoCardsBlock
        }
        ... on SanityCollectionSliderBlock {
          ...SanityCollectionSliderBlock
        }
        ... on SanityLadderCollectionGridBlock {
          ...SanityLadderCollectionGridBlock
        }
        ... on SanityLadderProductSlider {
          ...SanityLadderProductSlider
        }
        ... on SanitySquareImageSlider {
          ...SanitySquareImageSlider
        }
        ... on SanityImageBlock {
          ...SanityImageBlock
        }
        ... on SanityHeroSmallImage {
          ...SanityHeroSmallImage
        }
        ... on SanityDivider {
          ...SanityDivider
        }
      }
    }
  }
`;
