import React, { useContext } from 'react';
import { SanitySellPageInfoCardsBlockFragment } from 'graphql-types';
import { InfoCards } from './InfoCards';
import { InfoCardProps } from './model';
import { ThemeContext } from 'styled-components';

export const SanityInfoCards: React.FC<{
  fields: SanitySellPageInfoCardsBlockFragment;
}> = ({ fields }) => {
  const theme = useContext(ThemeContext);
  return (
    <InfoCards
      backgroundColor={fields.backgroundColor?.hex || theme.colors.basic100}
      infoCards={
        fields.infoCards?.map(
          (infoCard): InfoCardProps => ({
            infoCardImage: infoCard?.infoCardImage?.asset?.gatsbyImageData,
            titleText: infoCard?.titleText,
            titleTextColor:
              infoCard?.titleTextColor?.hex || theme.colors.default,
            subTitle: infoCard?.subTitle,
            subTitleTextColor:
              infoCard?.subTitleTextColor?.hex || theme.colors.default,
          })
        ) || []
      }
    />
  );
};
