import React from 'react';
import { SanityHeroSmallImageFragment, SanityImage } from 'graphql-types';
import { useIsBrowser, useUserData } from '@bbnb/openfit-frontend-shared';
import { HeroSmallImage } from './HeroSmallImage';
import { GradientBackgroundColor } from 'components/Common/Background';
import { Button2Variant } from 'components/Common/Button/model';
import {
  ViolatorProps,
  ViolatorAlignment,
} from 'components/Common/Violator/Violator.model';
import { LoadingPlaceHolder } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder';
import { PlaceholderContainer } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder.styles';
import { isValidForUserType } from 'helpers/Customer';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

interface Props {
  fields: SanityHeroSmallImageFragment;
}

export const HeroSmallImageSanity = ({ fields }: Props) => {
  const { role, loading } = useUserData();
  const isEntitled = role === 'subscriber';
  const isBrowser = useIsBrowser();

  if (!isBrowser || loading) {
    return (
      <PlaceholderContainer>
        <LoadingPlaceHolder width={'1312px'} height={'125px'} />
      </PlaceholderContainer>
    );
  }

  if (
    !isValidForUserType(
      isEntitled,
      (fields.userType || '') as 'non-entitled' | 'entitled' | ''
    )
  ) {
    return null;
  }

  const smallImage = getSimplifiedImageData(fields.image as SanityImage);

  return (
    <HeroSmallImage
      title={fields.title}
      text={fields._rawText}
      image={smallImage}
      bgColor={fields.bgColor as GradientBackgroundColor}
      bgImage={{
        desktop: fields.backgroundImage?.desktopImage as SanityImage,
        mobile: fields.backgroundImage?.mobileImage as SanityImage,
      }}
      desktopImageHeight={fields.desktopImageHeight}
      mobileImageHeight={fields.mobileImageHeight}
      ctaText={fields.ctaText}
      ctaUrl={fields.ctaUrl} // deprecated
      ctaLinkObject={fields.ctaLinkObject}
      ctaVariant={fields.ctaVariant as Button2Variant}
      violator={
        fields.violator?.map(
          (violator): ViolatorProps => ({
            text: violator?.violatorText || '',
            textColor: violator?.violatorTextColor?.hex,
            bgColor: violator?.violatorBgColor?.hex,
            alignment: violator?.violatorAlignment as ViolatorAlignment,
          })
        )?.[0]
      }
    />
  );
};
