import React from 'react';

import { logEvent, awaitClick } from 'helpers/Amplitude';
import { getIcid } from '@bbnb/openfit-frontend-shared';

import { PDPPageContainer } from 'components/Pdp';
import { SanityRichText } from 'components/Common/SanityRichText';
import { Violator } from 'components/Common/Violator/Violator';
import { ResponsiveBackgroundImage } from 'components/Common/Background';
import {
  GradientBackground,
  GradientBackgroundColor,
} from 'components/Common/Background';
import { Button2 } from 'components/Common/Button';
import { Button2Variant } from 'components/Common/Button/model';

import {
  HeroSmallImageProps,
  HeroSmallImageContentProps,
} from './HeroSmallImage.model';
import {
  Wrapper,
  LeftCol,
  RightCol,
  ImageHolder,
  Image,
  Title,
  Text,
  ButtonWrapper,
} from './HeroSmallImage.styles';
import { getUrl } from 'helpers/LinkObject';

export const HeroSmallImage: React.FC<HeroSmallImageProps> = ({
  bgColor,
  bgImage,
  desktopImageHeight,
  mobileImageHeight,
  ...contentProps
}) => {
  if (bgImage?.desktop || bgImage?.mobile) {
    const desktopHeight = desktopImageHeight || 250;
    const mobileHeight = mobileImageHeight || 200;
    return (
      <PDPPageContainer>
        <ResponsiveBackgroundImage
          images={bgImage}
          height={{ desktop: desktopHeight, mobile: mobileHeight }}
        >
          <HeroSmallImageContent {...contentProps} withBG />
        </ResponsiveBackgroundImage>
      </PDPPageContainer>
    );
  }

  return (
    <PDPPageContainer>
      <GradientBackground bgColor={bgColor || GradientBackgroundColor.LadderBG}>
        <HeroSmallImageContent {...contentProps} />
      </GradientBackground>
    </PDPPageContainer>
  );
};

const HeroSmallImageContent: React.FC<
  HeroSmallImageContentProps & { withBG?: boolean }
> = ({
  title,
  text,
  image,
  violator,
  ctaText,
  ctaUrl, // deprecated
  ctaLinkObject,
  ctaVariant,
  withBG = false,
}) => {
  return (
    <Wrapper>
      {violator && <Violator {...violator} />}
      <LeftCol withViolator={!!violator} withBG={withBG}>
        <Title withBG={withBG}>{title}</Title>
        <Text>
          <SanityRichText blocks={text} />
        </Text>
        {ctaText && (ctaUrl || ctaLinkObject) && (
          <ButtonWrapper>
            <Button2
              href={getUrl(ctaLinkObject, ctaUrl)}
              variant={ctaVariant || Button2Variant.SecondaryBlue}
              onClick={awaitClick((e) => {
                const icid = getIcid();
                return logEvent('Hero Clicked', {
                  key: e.currentTarget.href,
                  ...(icid && { icid }),
                });
              })}
            >
              {ctaText}
            </Button2>
          </ButtonWrapper>
        )}
      </LeftCol>
      <RightCol>
        <ImageHolder>
          {image && (
            <Image
              image={image}
              imgStyle={{ objectFit: 'contain' }}
              alt={title || ''}
            />
          )}
        </ImageHolder>
      </RightCol>
    </Wrapper>
  );
};
