import React, { useEffect } from 'react';
import { SanityEmailSignupBlockFragment } from 'graphql-types';
import { SanityRichText } from 'components/Common/SanityRichText';
import {
  Container,
  Title,
  EmailInputContainer,
  EmailInput,
  StyledSubmit,
  RightChevronContainer,
  RightChevron,
  InfoContainer,
  SuccessContainer,
  SuccessTitle,
  SuccessSubtext,
  ServerErrorMessage,
  ServerErrorContainer,
} from './EmailSignUp.styles';
import { useEmailForm } from 'hooks';
import { useLocation } from '@reach/router';

type EmailSignupProps = {
  title: SanityEmailSignupBlockFragment['title'];
  titleColor?: string;
  backgroundColor?: string;
  successTitle: SanityEmailSignupBlockFragment['successTitle'];
  successSubtext: SanityEmailSignupBlockFragment['successSubtext'];
  copy: SanityEmailSignupBlockFragment['_rawCopy'];
};

export const EmailSignUp: React.FC<EmailSignupProps> = ({
  title,
  titleColor,
  backgroundColor,
  successTitle,
  successSubtext,
  copy = [],
}) => {
  const { search } = useLocation();

  const {
    setNeedCheckEmail,
    setEmail,
    setProduct,
    setCoupon,
    handleSubmit,
    emailValidationMessage,
    showSuccess,
    serverError,
  } = useEmailForm();

  useEffect(() => {
    const params = new URLSearchParams(search);
    setProduct(params.get('product') || '');
    setCoupon(params.get('coupon') || '');
  }, [search]);

  return (
    <Container bg={backgroundColor}>
      {showSuccess ? (
        <SuccessContainer>
          <SuccessTitle>{successTitle}</SuccessTitle>
          <SuccessSubtext>{successSubtext}</SuccessSubtext>
        </SuccessContainer>
      ) : (
        <>
          <Title color={titleColor}>{title}</Title>
          <EmailInputContainer
            onSubmit={handleSubmit}
            data-test-id="email-signup-form"
            method="post"
          >
            <EmailInput
              placeholder="Enter your email"
              type="email"
              onBlur={(): void => {
                setNeedCheckEmail(true);
              }}
              onChange={(e): void => {
                setEmail(e.target.value);
              }}
              error={!!emailValidationMessage}
            />
            <StyledSubmit type="submit" value="Subscribe" />
            <RightChevronContainer>
              <RightChevron />
            </RightChevronContainer>
          </EmailInputContainer>
          {serverError && (
            <ServerErrorContainer>
              <ServerErrorMessage>
                Service is down. Please try again later.
              </ServerErrorMessage>
            </ServerErrorContainer>
          )}
        </>
      )}
      <InfoContainer>
        <SanityRichText blocks={copy} />
      </InfoContainer>
    </Container>
  );
};
