import React from 'react';
import { SanitySubmenuFragment } from 'graphql-types';

import { Submenu } from './Submenu';

interface Props {
  fields: SanitySubmenuFragment;
}

export const SubmenuSanity = ({ fields }: Props) => (
  <Submenu
    items={
      fields.items?.map((item) => ({
        name: item?.name || '',
        url: item?.url || '',
      })) || []
    }
    bgColorDesktop={fields.bgColorDesktop?.hex}
    bgColorMobile={fields.bgColorMobile?.hex}
    textColorDesktop={fields.textColorDesktop?.hex}
    textColorMobile={fields.textColorMobile?.hex}
    desktopOpacity={fields.desktopOpacity}
    fixedPosition={fields.fixedPosition}
    hideOnDesktop={fields.hideOnDesktop}
    hideOnMobile={fields.hideOnMobile}
  />
);
