import React from 'react';
import { navigate } from 'gatsby';

import { PDPPageContainer } from 'components/Pdp';
import { ProductTile } from 'components/Product/Tile/ProductTile';
import { ProductTileProps } from 'components/Product/Tile/ProductTile.model';

import * as S from './CollectionGrid.styles';
import { CollectionGridProps } from './CollectionGrid.model';
import { getProgramId, logEvent } from 'helpers/Amplitude';
import { getIcid } from '@bbnb/openfit-frontend-shared';

export const CollectionGrid: React.FC<CollectionGridProps> = ({
  title,
  description,
  tiles,
  collectionName,
}) => {
  const onTileClick = (tile: ProductTileProps) => {
    tile.link &&
      navigate(tile.link, {
        state: {
          collectionTitle: collectionName,
          collectionUrl: window.location.pathname,
        },
      });
    const programId = getProgramId();
    const icid = getIcid();
    const product = tile.title;
    logEvent('Collection Page: CTA Clicked ', {
      ...((programId && { programId }) || {}),
      ...(icid && { icid }),
      ...(product && { product }),
    });
  };

  return (
    <PDPPageContainer>
      {title && <S.Title>{title}</S.Title>}
      {description && <S.Description>{description}</S.Description>}
      <S.Wrapper>
        {tiles.map((tile, index) => (
          <div
            key={index}
            style={{
              zIndex: tiles.length - index,
            }}
          >
            <ProductTile {...tile} onClick={onTileClick} />
          </div>
        ))}
      </S.Wrapper>
    </PDPPageContainer>
  );
};
