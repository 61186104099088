import React from 'react';
import {
  Container,
  TitleContainer,
  Title,
  InfoCardsContainer,
  IconContainer,
  Icon,
  Info,
  InfoText,
} from './styles';
import { InfoCardsProps, InfoCardProps } from './model';
import { SellPageContainer } from 'components/Common/Layout';

export const InfoCards: React.FC<InfoCardsProps> = ({
  backgroundColor,
  infoCards,
}) => {
  return (
    <Container background={backgroundColor}>
      <SellPageContainer>
        <InfoCardsContainer>
          {infoCards &&
            infoCards.map(
              ({
                name,
                infoCardImage,
                titleTextColor,
                titleText,
                subTitleTextColor,
                subTitle,
              }: InfoCardProps) => (
                <IconContainer key={name}>
                  <Icon image={infoCardImage} alt={''} objectFit="contain" />
                  <TitleContainer>
                    <Title color={titleTextColor} />
                    {titleText}
                  </TitleContainer>
                  <Info>
                    <InfoText color={subTitleTextColor}>{subTitle}</InfoText>
                  </Info>
                </IconContainer>
              )
            )}
        </InfoCardsContainer>
      </SellPageContainer>
    </Container>
  );
};
