import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  color,
  fontSize,
  minWidth,
  maxWidth,
  space,
  ColorProps,
} from 'styled-utils';
import chevron from 'svg/chevron.svg';

export const Container = styled.div<ColorProps>`
  display: flex;
  flex-direction: column;
  border: solid ${({ theme }) => theme.colors.basic400};
  border-width: 1px 0;
  ${({ bg, theme }) => color({ backgroundColor: bg || theme.colors.basic100 })};
`;

export const Title = styled.p<ColorProps>`
  font-weight: 900;
  line-height: 24px;
  text-align: center;
  ${fontSize({ fontSize: ['18px', '24px'] })};
  ${space({ p: ['32px 0 16px', '40px 0 16px 0'] })};
  ${({ color: fontColor, theme }) =>
    color({ color: fontColor || theme.colors.basic800 })};
`;

export const EmailInputContainer = styled.form`
  display: flex;
  align-self: center;
  flex-direction: row;
  position: relative;
  ${maxWidth({ maxWidth: ['344px', '414px'] })};
`;

export const EmailInput = styled.input<{
  error: boolean;
}>`
  border-radius: 9px;
  min-height: 46px;
  padding-left: 20px;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.red800 : theme.colors.basic400};
  ${minWidth({ minWidth: ['344px', '414px'] })};
  ::placeholder {
    font-size: 12px;
  }
`;

export const StyledSubmit = styled.input`
  background: transparent;
  border: none;
  font-size: 12px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  height: 100%;
`;

export const RightChevronContainer = styled.span`
  height: 100%;
  position: absolute;
  right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const RightChevron: React.FC = () => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      color={theme.colors.blue400}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      dangerouslySetInnerHTML={{
        __html: `<use xlink:href="${chevron.url}"></use>`,
      }}
    />
  );
};

export const InfoContainer = styled.div`
  text-align: center;
  align-self: center;
  ${space({ mx: '15px' })};
  max-width: 480px;
  padding: 7px 0;
  p {
    font-size: 12px;
    a {
      color: ${({ theme }) => theme.colors.blue400};
    }
  }
`;

export const SuccessContainer = styled.div`
  text-align: center;
  align-self: center;
`;

export const SuccessTitle = styled.h4`
  font-weight: 900;
  line-height: 24px;
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.basic800};
  ${space({ p: ['32px 0 16px'] })};
`;

export const SuccessSubtext = styled.p`
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  padding-bottom: 20px;
  color: ${({ theme }) => theme.colors.basic800};
  ${fontSize({ fontSize: ['14px', '18px'] })};
`;

export const ServerErrorContainer = styled.div`
  text-align: left;
  align-self: center;
  max-width: 400px;
  width: 480px;
  display: flex;
  margin-top: 5px;
`;

export const ServerErrorMessage = styled.p`
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.red800};
  font-size: 12px;
`;
