import React, { FC, useMemo, useState, MouseEvent } from 'react';
import { useLocation } from '@reach/router';
import { SquareImageSliderEntry } from './SquareImageSliderEntry';
import { Slider } from './Slider';
import {
  SanitySquareImageSliderEntry,
  SanitySquareImageSliderFragment,
} from 'graphql-types';
import {
  ButtonArrow,
  CloseButton,
  Container,
  CtaButton,
  CtaVideo,
  ModalInnerContainer,
  SquareImageContainer,
  SubtitleContainer,
  Title,
  VideoContainer,
} from './SquareImageSlider.styles';
import { SanityRichText } from 'components/Common/SanityRichText';
import { logEvent, mapAndFormatSearchParams } from 'helpers/Amplitude';
import { YoutubeModal } from '../Common/YoutubeModal/YoutubeModal';
import { useIsMobile } from 'hooks/use-media-query';
import { useIsBrowser } from 'utils/general';
import { generateYoutubeEmbedLink } from 'components/Common/YoutubeModal/YoutubeModal.helpers';
import { LoadingPlaceHolder } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder';
import { PlaceholderContainer } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder.styles';

export const SquareImageSlider: FC<SanitySquareImageSliderFragment> = ({
  images,
  title,
  titleColor,
  _rawSubtitle,
  backgroundColor,
  ctaText,
  ctaLink,
}) => {
  const { search } = useLocation();
  const [showVideoModal, setShowVideoModal] = useState(false);
  const ctaIsVideo = ctaLink?.includes('youtube');
  const isBrowser = useIsBrowser();
  const isMobile = useIsMobile();

  const renderImages = useMemo(
    () =>
      images && images.length
        ? images.map((image: SanitySquareImageSliderEntry) => (
            <SquareImageSliderEntry
              key={image && image.image?._key}
              image={image && image.image}
              _rawCaption={image && image._rawCaption}
            ></SquareImageSliderEntry>
          ))
        : null,
    [images]
  );

  // Due to a bug in the keen slider's ref logic, mobile product slider renders as blank in Safari
  // when the back button is used, which is fixed by not SSR rendering this component.
  if (!isBrowser || images?.length === 0) {
    return (
      <PlaceholderContainer>
        <LoadingPlaceHolder width={'1312px'} height={'521px'} />
      </PlaceholderContainer>
    );
  }

  const handleCtaClick = (e: MouseEvent) => {
    const params = mapAndFormatSearchParams(new URLSearchParams(search));
    if (ctaIsVideo) {
      setShowVideoModal(true);
      e.preventDefault();
    }
    return logEvent('CTA Click', {
      ...((params.get('icid') && { icid: params.get('icid') }) || {}),
    });
  };

  return renderImages ? (
    <Container background={backgroundColor}>
      {showVideoModal && (
        <YoutubeModal onOverlayClick={() => setShowVideoModal(false)}>
          <ModalInnerContainer>
            <CloseButton onClick={() => setShowVideoModal(false)} />
            <VideoContainer>
              <CtaVideo
                title={ctaText}
                src={generateYoutubeEmbedLink(ctaLink || '')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              ></CtaVideo>
            </VideoContainer>
          </ModalInnerContainer>
        </YoutubeModal>
      )}
      {title ? <Title color={titleColor as string}>{title}</Title> : null}
      {_rawSubtitle ? (
        <SubtitleContainer>
          <SanityRichText blocks={_rawSubtitle} />
        </SubtitleContainer>
      ) : null}
      {ctaText ? (
        <CtaButton
          onClick={handleCtaClick}
          href={ctaLink}
          variant={'secondaryInverted'}
        >
          <ButtonArrow />
          {ctaText}
        </CtaButton>
      ) : null}

      {isMobile ? (
        <Slider autoRotate>{renderImages}</Slider>
      ) : (
        <SquareImageContainer>{renderImages}</SquareImageContainer>
      )}
    </Container>
  ) : null;
};
