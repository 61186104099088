import { GatsbyImage } from 'components/Common/GatsbyImage';
import styled from 'styled-components';
import { space, flexbox, typography, layout } from 'styled-utils';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const LeftCol = styled.div<{ withViolator: boolean; withBG: boolean }>`
  flex: 1;
  ${({ withBG }) => flexbox({ flexBasis: [withBG ? '50%' : '218px', '50%'] })};
  ${({ withViolator }) =>
    space({
      pl: [16, 40],
      pr: [0, 40],
      pt: withViolator ? [47, 52] : [22, 46],
      pb: [22, 46],
    })};
  box-sizing: border-box;
`;

export const RightCol = styled.div`
  flex: 1;
  ${flexbox({ flexBasis: ['157px', '50%'] })};
  position: relative;
`;

export const ImageHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Image = styled(GatsbyImage)`
  ${layout({ width: ['100%', 362] })};
  height: 100%;
`;

export const Title = styled.h3<{ withBG: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${({ withBG }) =>
    typography({
      fontSize: [withBG ? 14 : 20, 26],
      lineHeight: [withBG ? '20px' : '24px', '36px'],
    })};
  ${({ withBG }) => space({ mb: [withBG ? 0 : '6px', '16px'] })};
`;

export const Text = styled.div`
  p {
    margin: 2px;
    ${typography({ fontSize: [12, 14], lineHeight: ['16px', '20px'] })};
  }
`;

export const ButtonWrapper = styled.div`
  ${space({ mt: ['6px', '16px'] })};
`;
