import React, { FC } from 'react';
import { SquareImageSlider } from './SquareImageSlider';
import { SanitySquareImageSliderFragment } from 'graphql-types';

interface Props {
  fields: SanitySquareImageSliderFragment;
}

export const SanitySquareImageSlider: FC<Props> = ({ fields }) => (
  <SquareImageSlider {...fields} />
);
