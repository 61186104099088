import styled, { css } from 'styled-components';
import { space, color, flexbox, layout } from 'styled-utils';
import { transparentize } from 'color2k';

import { SubmenuVisualProps } from './Submenu.model';
import { Link } from 'gatsby';

export const Wrapper = styled.div<SubmenuVisualProps>`
  position: ${({ fixedPosition }) => (fixedPosition ? 'initial' : 'fixed')};
  z-index: 20;
  top: 60px;
  @media (min-width: 980px) {
    top: 70px;
  }
  left: 0;
  width: 100vw;
  height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  ${flexbox({ justifyContent: ['flex-start', 'center'] })};
  ${({
    fixedPosition,
    bgColorMobile,
    bgColorDesktop,
    desktopOpacity,
    hideOnDesktop,
    hideOnMobile,
  }) => {
    const opacity = 1 - (desktopOpacity || (fixedPosition ? 1 : 0.1));
    return css`
      ${({ theme }) =>
        color({
          backgroundColor: [
            bgColorMobile || theme.colors.basic100,
            transparentize(bgColorDesktop || theme.colors.basic800, opacity),
          ],
        })}
      ${layout({
        display: [
          hideOnMobile ? 'none' : 'flex',
          hideOnDesktop ? 'none' : 'flex',
        ],
      })}
    `;
  }};
  transition: border-bottom-color 300ms ease-in-out;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ isOnTop, theme }) =>
    isOnTop ? theme.colors.basic100 : theme.colors.basic400};
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemOuter = styled.div`
  ${space({ px: ['6px', 0] })};
  &:first-child {
    ${space({ pl: ['10px', 0] })};
  }
  &:last-child {
    ${space({ pr: ['10px', 0] })};
  }
`;

export const Item = styled(Link)<SubmenuVisualProps>`
  height: 30px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.basic150 : 'inherited'};
  border-radius: ${({ isActive }) => (isActive ? '24px' : '0')};
  display: flex;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
  ${space({ px: [10, 20] })};
  ${({ textColorMobile, textColorDesktop, isActive, theme }) =>
    css`
      ${color({
        color: isActive
          ? theme.colors.basic800
          : [
              textColorMobile || theme.colors.black,
              textColorDesktop || theme.colors.basic100,
            ],
      })}
    `};

  @media (min-width: 1025px) {
    &:hover {
      color: ${({ theme }) => theme.colors.basic800};
    }
  }
`;
