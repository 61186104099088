import { useState, useEffect } from 'react';
import { useIsBrowser } from 'utils/general';

type Position = {
  top: number;
  left: number;
};

export const useScroll = (): Position => {
  const isBrowser = useIsBrowser();
  const [pos, setPos] = useState<Position>({
    top: isBrowser ? window.pageYOffset : 0,
    left: isBrowser ? window.pageXOffset : 0,
  });

  const handleScroll = () => {
    setPos({ top: window.pageYOffset, left: window.pageXOffset });
  };

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', handleScroll, false);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isBrowser]);

  return pos;
};
