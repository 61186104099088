import React, { useRef, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { Wrapper, ItemOuter, Item } from './Submenu.styles';
import { SubmenuProps } from './Submenu.model';
import { awaitClick, logEvent } from 'helpers/Amplitude';
import { useScroll } from 'utils/use-scroll';
import { getIcid } from '@bbnb/openfit-frontend-shared';
import { isRelativeUrl } from 'helpers/General';

const REMOVE_SLASH_REGEX = /\//g;

export const Submenu: React.FC<SubmenuProps> = (props) => {
  const { items, ...visualProps } = props;
  const activeElementRef = useRef<HTMLDivElement>(null);

  const { top } = useScroll();
  const isOnTop = top === 0;

  useEffect(() => {
    activeElementRef.current?.scrollIntoView({
      block: 'end',
      inline: 'end',
    });
  }, [activeElementRef]);

  const currentPath = useLocation()?.pathname.replace(REMOVE_SLASH_REGEX, '');

  const onItemClick = awaitClick((event) => {
    const icid = getIcid();
    const href = event.currentTarget.getAttribute('href');

    let promise = logEvent('Subnav Click ', {
      ...(icid && { icid }),
    });

    if (href && isRelativeUrl(href)) {
      // Don't wait on analytics for internal navigation.
      promise = Promise.resolve();
    }

    return promise;
  });

  return (
    <Wrapper {...visualProps} isOnTop={isOnTop}>
      {items.map((item, index) => {
        const isActive = item.url
          .replace(REMOVE_SLASH_REGEX, '')
          .includes(currentPath);
        return (
          <ItemOuter key={index} ref={isActive ? activeElementRef : null}>
            <Item
              {...visualProps}
              to={item.url}
              onClick={onItemClick}
              isActive={isActive}
            >
              {item.name}
            </Item>
          </ItemOuter>
        );
      })}
    </Wrapper>
  );
};
