import React from 'react';
import { EmailSignUp } from './EmailSignUp';
import { SanityEmailSignupBlockFragment } from 'graphql-types';

type SanityEmailSignUpProps = {
  fields: SanityEmailSignupBlockFragment;
};

export const SanityEmailSignUp: React.FC<SanityEmailSignUpProps> = ({
  fields,
}) => {
  return (
    <EmailSignUp
      backgroundColor={fields.backgroundColor?.hex}
      title={fields.title}
      titleColor={fields.titleColor?.hex}
      copy={fields._rawCopy}
      successTitle={fields.successTitle}
      successSubtext={fields.successSubtext}
    />
  );
};
