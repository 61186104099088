import styled from 'styled-components';
import { maxWidth } from 'styled-utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 45px 20px 0 0;
  ${maxWidth({ maxWidth: ['100%', '176px'] })};
  overflow: hidden;

  &:last-child {
    margin: 45px 0 0 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 176px;
`;
